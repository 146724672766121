import React from 'react';
import LoadingIndicator from "../LoadingIndicator";
import { useBranding } from "@util/branding.util";
import { Brand } from "@/types";

interface ButtonProps {
  children: React.ReactNode;
  onClick: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  type?: "button" | "submit";
  variant: string;
  autoFocus?: boolean;
}

const Button = (props: ButtonProps) => {

  const {
    type, variant, onClick, isLoading, children, disabled, autoFocus
  }: ButtonProps = props;

	const branding = useBranding();

  return (
    <button
      autoFocus={ autoFocus ?? false }
      type={ type }
      disabled={ disabled }
      onClick={ () => onClick() }
      className={ `
			relative gap-2 flex-1 inline-flex items-center justify-center min-h-[51px] h-[51px]
			px-6 text-md font-medium rounded-md focus:outline-none 
			focus:ring-2 focus:ring-offset-2 focus:ring-primary transition-all
			${ variant === 'default' && !disabled ? 'bg-white text-gray-700 border border-gray-300 shadow-sm hover:bg-gray-50 active:bg-gray-100' : '' }
			${ variant === 'primary' && !disabled ? 'bg-primary text-white shadow-sm hover:bg-primary-600 active:bg-primary-700' : '' }
			${ variant === 'green' && !disabled ? 'bg-green-600 text-white shadow-sm hover:bg-green-700 active:bg-green-800' : '' }
			${ variant === 'warning' && !disabled ? 'bg-[#FBF8E5] text-[#886D42] border-2 border-[#D8CFB6] shadow-sm hover:bg-[#EBE9D7] active:bg-[#FBF8E5]' : '' }
			${ variant === 'transparent' && !disabled ? 'bg-transparent text-gray-700 hover:bg-gray-100 active:bg-gray-200' : '' }
			
			${ variant === 'default' && disabled ? 'bg-gray-100 text-gray-400 border border-gray-200 shadow-sm' : '' }
			${ variant === 'primary' && disabled ? 'bg-gray-100 text-gray-400 border border-gray-200 shadow-sm' : '' }
			${ variant === 'green' && disabled ? 'bg-gray-100 text-gray-400 border border-gray-200 shadow-sm' : '' }
			${ variant === 'warning' && disabled ? 'bg-gray-100 text-gray-400 border border-gray-200 shadow-sm' : '' }
			${ variant === 'transparent' && disabled ? 'bg-transparent text-gray-400' : '' } 
			` }
    >
      { isLoading && <LoadingIndicator/> }
      { !isLoading && children }
    </button>
  )
}

export default Button;
