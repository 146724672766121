import React, { useId } from 'react';
import { ExclamationCircleIcon } from "@heroicons/react/solid";
import LoadingIndicator from "@components/LoadingIndicator";

interface InputTextProps extends React.HTMLProps<HTMLInputElement> {
  onChange: (e) => void,
  label: string;
  error?: string;
  isLoading?: boolean;
  className?: string;
}

const InputText = (props: InputTextProps) => {
  const elementId = useId();

  const {
    label, error, type, className, ...rest
  }: InputTextProps = props;


  return (
    <div className={ className }>
      <div
        className={ `transition-all relative bg-white border ${ error ? 'border-red-300 text-red-900 placeholder-red-300' : 'border-gray-300' } rounded-md px-3 py-2 shadow-sm focus-within:ring-1 focus-within:ring-primary focus-within:border-primary` }>
        <label htmlFor={ elementId } className={ 'block text-xs font-medium text-gray-800' }>
          { label }
        </label>
        {/*// @ts-ignore*/ }
        <input
          id={ elementId }
          type={ type }
          className={ 'block w-full border-none text-md focus:ring-0 p-0 text-gray-900 placeholder-gray-400 focus:outline-none' }
          { ...rest }
        />
        { error && <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <ExclamationCircleIcon className="h-5 w-5 text-red-500" aria-hidden="true"/>
        </div> }
      </div>
      { props.isLoading &&
          <div className={ 'absolute top-4 right-4' }>
              <div className={ 'scale-75' }>
                  <LoadingIndicator/>
              </div>
          </div>
      }
      { error && <p className={ 'mt-2 text-sm text-red-600' }>{ error }</p> }
    </div>
  )
}

export default InputText;
